<template>
  <base-layout>
    <template v-slot:toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          color="light"
          @click="backToSignIn"
          default-href="/signin/email"
        />
      </ion-buttons>
    </template>
    <div class="containerBox">
      <ion-input
        placeholder="Email"
        type="email"
        class="inputBox email"
        required="true"
        id="email"
        v-on:ionInput="storeEmail"
      ></ion-input>
      <ion-button class="inputBox signInButton" @click="onButtonClick"
        >Send password reset email</ion-button
      >
    </div>
  </base-layout>
</template>

<script>
import { IonButtons, IonBackButton, IonInput, IonButton } from "@ionic/vue";

import { resetPassword } from "@/services/firebase.js";

export default {
  methods: {
    backToSignIn() {
      this.$router.push("/signin/email");
    },
    onButtonClick() {
      if (this.email != "") {
        resetPassword(this.email);
      }
    },
    storeEmail() {
      this.email = document.getElementById("email").value;
    },
    storePassword() {
      this.password = document.getElementById("pass").value;
    },
  },
  components: [IonButtons, IonBackButton, IonInput, IonButton],
  data() {
    return {
      email: "",
    };
  },
};
</script>

<style scoped>
.inputBox {
  color: var(--ion-color-light-contrast);
  background: var(--ion-color-light-tint);
  /*border: 2px solid white;*/
  width: 80%;
  left: 10%;
  position: absolute;
  height: 40px;
  box-shadow: 2px;
  --padding-start: 10px;
}
.email {
  top: 0px;
}
.signInButton {
  top: 60px;
  color: black;
  font-weight: bold;
}
.containerBox {
  width: 100%;
  height: 240px;
  position: absolute;
  left: 0px;
  top: calc(50% - 150px);
}
</style>
